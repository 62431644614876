import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import CustomCheckbox from "Components/CustomCheckbox/CustomCheckbox";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import deleteIcon from "../../assets/all-svg-files/delete.svg";
import editIcon from "../../assets/all-svg-files/edit.svg";
import trashCan from "../../assets/all-svg-files/trash-can.svg";
import viewIcon from "../../assets/all-svg-files/view.svg";

const ActionIcons = ({ onView, onEdit, onDelete }) => {
    return (
        <div className="user-management-container__action-container">
            <img src={viewIcon} alt="view icon" onClick={onView} />
            <img src={editIcon} alt="edit icon" onClick={onEdit} />
            <img src={deleteIcon} alt="delete icon" onClick={onDelete} />
        </div>
    );
};
const CardViewDetail = ({ title, packageName, price, actualValue, discountPackage, credits, dateCreated, status }) => {
    const { Paragraph } = Typography;

    // Combine all details into a single array
    const details = [
        { label: "Plan Name:", value: packageName || null },
        { label: "Credits:", value: credits || "Unlimited" },
        { label: "Price:", value: price ? `$${price}` : null },
        { label: "Created Date:", value: dateCreated ? dayjs(dateCreated).format("MM-DD-YYYY") : null },
        { label: "Actual Value:", value: actualValue ? `$${actualValue}` : null },
        { label: "Status:", value: parseInt(status) > 0 ? "Active" : "Inactive" },
        { label: "Discount Plan For:", value: discountPackage || null }
    ].filter((item) => item.value !== null);

    const style = {
        container: {
            marginBottom: 26
        },
        headerSec: {
            flexDirection: "row",
            display: "flex",
            marginBottom: 10
        },
        gridStyle: {
            minWidth: "50%",
            maxWidth: "100%"
        },
        label: {
            color: "#535353",
            fontWeight: "500"
        },
        value: {
            color: "#8A8989",
            fontWeight: "500"
        },
        detailRow: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 8 // Space between rows
        },
        columnContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap", // Allow wrapping to adjust for smaller screens
            width: "100%"
        },
        col: {
            flexBasis: "48%", // Each column takes up about 48% of the width
            boxSizing: "border-box" // Prevent overflow
        }
    };

    return (
        <div style={style.container}>
            <div style={style.headerSec}>
                <Link to={-1} style={{ display: "flex", marginRight: 10 }}>
                    <LeftOutlined style={{ fontSize: "1.3em" }} />
                </Link>
                <h1 style={{ margin: 0 }}>{title}</h1>
            </div>
            <Card style={style.gridStyle}>
                <div style={style.columnContainer}>
                    {details.map((item, index) => (
                        <Col style={style.col} key={index}>
                            <div style={style.detailRow}>
                                <Paragraph style={style.label}>{item.label}</Paragraph>
                                <Paragraph style={style.value}>{item.value}</Paragraph>
                            </div>
                        </Col>
                    ))}
                </div>
            </Card>
        </div>
    );
};

export default CardViewDetail;

const DeleteConfirmationModal = ({ isVisible, onConfirm, onCancel, isLoading }) => {
    return (
        <Modal
            className="subscription-plans-container__modal"
            open={isVisible}
            centered
            onOk={onConfirm}
            onCancel={onCancel}
            cancelText="No"
            okText="Yes"
            confirmLoading={isLoading}
            cancelButtonProps={{ disabled: isLoading }}
        >
            <div className="deleteModal">
                <img src={trashCan} alt="Trash Can" />
                <p>Are you sure you want to delete this</p>
                <p>Subscription?</p>
            </div>
        </Modal>
    );
};

const PlanModal = ({
    isVisible,
    onCancel,
    onFinish,
    initialValues = {},
    isEditing,
    subscriptionOptions,
    packagesOptions,
    isLoading
}) => {
    const [form] = Form.useForm();
    const isDiscountedPackage = Form.useWatch("isDiscountedPackage", form);
    const isSubscription = Form.useWatch("IsSubscription", form);
    const isNonConsumable = Form.useWatch("nonConsumable", form);
    const GooglePurchaseID = Form.useWatch("google_purchase_id", form);
    const IOSPurchaseID = Form.useWatch("ios_purchase_id", form);

    const validatePrice = (_, value) => {
        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            return Promise.reject(new Error("Enter a valid price (e.g., 1.00 or 9.99)."));
        }
        return Promise.resolve();
    };

    const validateDiscountPrice = (_, value) => {
        const originalPrice = form.getFieldValue("price"); // Fetch the original price
        const isDiscountedPackage = form.getFieldValue("isDiscountedPackage"); // Check if discounted package is enabled
        const roundedOriginalPrice = parseFloat(originalPrice).toFixed(2);

        if (isDiscountedPackage) {
            if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                return Promise.reject(new Error("Enter a valid discount price (e.g., 0.00, 1.00, or 9.99)."));
            }
            // return Promise.resolve();
        }
        const roundedValue = parseFloat(value).toFixed(2);

        // Allow discount price of 0 or more
        if (roundedValue < 0) {
            return Promise.reject(new Error("Discount price must be 0 or more."));
        }
        // if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        //     return Promise.reject(new Error("Enter a valid discount price (e.g., 0.00, 1.00, or 9.99)."));
        // }
        // Ensure the discount price is less than or equal to the original price
        if (parseFloat(roundedValue) > parseFloat(roundedOriginalPrice)) {
            return Promise.reject(new Error("Discount price must not exceed the original price."));
        }

        return Promise.resolve();
    };
    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };

    const handleSelectChange = (field) => (values) => {
        form.setFieldsValue({ [field]: values });
    };
    const handleSelectedItem = (values) => {
        const selectedOption = packagesOptions.find((option) => option.value === values);
        if (selectedOption) {
            form.setFieldValue("credits", selectedOption.credits);
            form.setFieldValue("price", selectedOption.price);
        }
    };
    const handleCheckboxChange = (field) => (e) => {
        form.setFieldsValue({ [field]: e.target.checked });
    };
    const handleDiscountedPackages = () => {
        const isDiscountedPackage = form.getFieldValue("isDiscountedPackage");

        if (isDiscountedPackage) {
            form.resetFields(["subscription_by", "IsSubscription", "nonConsumable"]);
        }
    };
    const handleIsSubscriptionCheckBox = () => {
        const IsSubscription = form.getFieldValue("IsSubscription");
        if (IsSubscription) {
            form.setFieldValue("isDiscountedPackage", false);
            form.resetFields(["credits", "price", "discount_package", "nonConsumable"]);
        }
    };
    const handleNonConsumableCheckBox = (e) => {
        const isNonConsumable = form.getFieldValue("nonConsumable");
        if (isNonConsumable) {
            form.setFieldValue("nonConsumable", e.target.checked);
            form.resetFields([
                "credits",
                "discount_price",
                "price",
                "discount_package",
                "IsSubscription",
                "isDiscountedPackage",
                "subscription_by"
            ]);
        }
    };
    useEffect(() => {
        const resetAndSetValue = () => {
            form.resetFields();
            form.setFieldsValue(initialValues);
        };
        resetAndSetValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues, isEditing]);

    return (
        <Modal footer={null} open={isVisible} centered onCancel={handleCancel} width={700}>
            <div className="subscription-plans-container__modal">
                <h2 className="__editSubscriptionTitle">{isEditing ? "Edit Plan" : "Create New Plan"}</h2>
                <Form form={form} name="planForm" onFinish={onFinish} autoComplete="off" layout="vertical">
                    <Row gutter={16} align={"middle"}>
                        <Col span={12}>
                            <Form.Item
                                name="isDiscountedPackage"
                                valuePropName="checked"
                                className={isDiscountedPackage ? `checkBoxStyleCheck` : `checkBoxStyle`}
                            >
                                <CustomCheckbox
                                    disabled={initialValues.nonConsumable}
                                    onChange={(e) => {
                                        handleDiscountedPackages(e);
                                        handleCheckboxChange(e);
                                    }}
                                    defaultChecked={initialValues.isDiscountedPackage || false}
                                >
                                    Discount Plan
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {isDiscountedPackage && (
                                <Form.Item
                                    name="discount_package"
                                    label="Plan"
                                    rules={[{ required: false, message: "Please select plan" }]}
                                >
                                    <Select
                                        defaultValue={initialValues.discount_package}
                                        className="SubscriptionDuration"
                                        placeholder="Select Plan"
                                        onChange={(e) => {
                                            handleSelectedItem(e);
                                            handleSelectChange(e);
                                        }}
                                        options={packagesOptions}
                                        showArrow
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={16} align={"middle"}>
                        <Col span={12}>
                            <Form.Item
                                name="IsSubscription"
                                valuePropName="checked"
                                className={isSubscription ? `checkBoxStyleCheck` : `checkBoxStyle`}
                            >
                                <CustomCheckbox
                                    disabled={initialValues.nonConsumable}
                                    onChange={
                                        (e) => {
                                            handleCheckboxChange(e);
                                            handleIsSubscriptionCheckBox(e);
                                        }
                                        // handleCheckboxChange("IsSubscription")
                                    }
                                    defaultChecked={initialValues.IsSubscription || false}
                                >
                                    Is Subscription
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {isSubscription && (
                                <Form.Item
                                    name="subscription_by"
                                    label="Subscription Duration"
                                    rules={[{ required: isSubscription ?? false, message: "Please select Duration" }]}
                                >
                                    <Select
                                        defaultValue={initialValues.subscription_by}
                                        className="SubscriptionDuration"
                                        placeholder="Select Duration"
                                        onChange={handleSelectChange("subscription_by")}
                                        options={subscriptionOptions}
                                        showArrow
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={16} align={"middle"}>
                        <Col span={12}>
                            <Form.Item
                                name="nonConsumable"
                                valuePropName="checked"
                                // className={isSubscription ? `checkBoxStyleCheck` : `checkBoxStyle`}
                            >
                                <CustomCheckbox
                                    disabled={initialValues.nonConsumable}
                                    onChange={
                                        (e) => {
                                            handleCheckboxChange(e);
                                            handleNonConsumableCheckBox(e);
                                            // handleIsSubscriptionCheckBox(e);
                                        }
                                        // handleCheckboxChange("IsSubscription")
                                    }
                                    defaultChecked={initialValues.nonConsumable || false}
                                >
                                    Non Consumable
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: "Please input the plan name!" }]}
                                label="Plan Name"
                            >
                                <Input placeholder="Enter Plan Name" />
                            </Form.Item>
                        </Col>
                        {!isSubscription && (
                            <Col span={12}>
                                <Form.Item
                                    name="credits"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input total credits!"
                                        }
                                    ]}
                                    label="Total Credits"
                                >
                                    <Input
                                        placeholder="Enter Total Credits"
                                        type="number"
                                        min={1}
                                        maxLength={1}
                                        max={5000}
                                        disabled={isDiscountedPackage}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: "Please input Detail!" }]}
                                label="Detail"
                            >
                                <Input placeholder="Enter Detail" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="google_purchase_id"
                                rules={[{ required: !IOSPurchaseID, message: "Please input Google Purchase ID!" }]}
                                label="Google Purchase ID"
                            >
                                <Input placeholder="Enter Google Purchase ID" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="ios_purchase_id"
                                rules={[{ required: !GooglePurchaseID, message: "Please input iOS Purchase ID!" }]}
                                label="iOS Purchase ID"
                            >
                                <Input placeholder="Enter iOS Purchase ID" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="price"
                                rules={[
                                    { required: true, message: "Please input the price!" },
                                    { validator: validatePrice }
                                ]}
                                label="Price"
                            >
                                <Input
                                    placeholder="Type a price"
                                    type="number"
                                    prefix="$"
                                    disabled={isDiscountedPackage}
                                />
                            </Form.Item>
                        </Col>
                        {!isNonConsumable && (
                            <Col span={12}>
                                <Form.Item
                                    name="discount_price"
                                    rules={[
                                        { message: "Please input the discount price!" },
                                        { validator: validateDiscountPrice }
                                    ]}
                                    label="Discount Price"
                                >
                                    <Input placeholder="Discount Price" type="number" prefix="$" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {isEditing ? "Update" : "Create"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

const FilterAndCreate = ({ filterLabel = "Filter By", statusOptions, onFilterChange, onCreatePlan }) => {
    const { Option } = Select;

    return (
        <Row align="middle" gutter={16} className="FilterAndCreateContainer">
            <Col>
                <span>{filterLabel}</span>
            </Col>
            <Col>
                <Select placeholder="Status" onChange={onFilterChange} style={{ width: 150 }}>
                    {statusOptions.map((status, index) => (
                        <Option key={index} value={status.value}>
                            {status.label}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col>
                <Button type="primary" style={{ backgroundColor: "#a89882" }} onClick={onCreatePlan}>
                    Create Plan
                </Button>
            </Col>
        </Row>
    );
};
export { ActionIcons, CardViewDetail, DeleteConfirmationModal, FilterAndCreate, PlanModal };

