import { message, notification } from "antd";
import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";
import { PAGE_SIZE } from "queries/queries.constant";
import {
    useMutationCreateRefferalCode,
    useMutationDeleteRefferalDetail,
    useMutationEditRefferal,
    useQueryEligiblePlansList,
    useQueryRefferalList
} from "queries/refferalcode.queries";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userTypes } from "util/util.constant";
import { RefferalCodeColumns } from "./constants";
dayjs.extend(utc);

export default function useRefferalCodeContainer() {
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
    const [search, setSearch] = useState(null);
    const [filterByStatus, setFilterByStatus] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDateCreatedModal, setisDateCreatedModal] = useState(false);
    const [isValidityModal, setisValidityModal] = useState(false);
    const [validityData, setvalidityData] = useState({ month: "", year: "" });
    const [selectedDateCreated, setSelectedDateCreated] = useState({ start_date: "", end_date: "" });
    const selectedIDRef = useRef(null);

    let userQueryPayload = {
        page,
        isPagination: true,
        type: userTypes.user,
        take: PAGE_SIZE,
        search: search,
        status: filterByStatus,
        ...(validityData.month && { month: validityData.month }),
        ...(validityData.year && { year: validityData.year }),
        ...(selectedDateCreated.start_date && { start_date: selectedDateCreated.start_date }),
        ...(selectedDateCreated.end_date && { end_date: selectedDateCreated.end_date })
    };
    const {
        data: refferalDataList,
        isLoading: isLoading_refferalList,
        refetch: refetchRefferalDataList
    } = useQueryRefferalList(userQueryPayload);
    const { mutate: CreateRefferalCodeMutateQuery, isLoading: isLoadingCreateRefferal } = useMutationCreateRefferalCode(
        (res) => {
            refetchRefferalDataList();
            handleCancel();
            notification.success({
                message: res?.message
            });
        }
    );
    const { data: eligiblePlansList, isLoading: isLoading_eligiblePlansList } = useQueryEligiblePlansList();
    const { mutate: deleteSubscriptionMutation, isLoading: isLoading_DeleteSubscription } =
        useMutationDeleteRefferalDetail((res) => {
            refetchRefferalDataList();
            setDeleteModalVisibility(false);
            message.success(res?.message);
        });
    const { mutate: updateRefferalDetailMutateQuery, isLoading: isLoadingUpdateRefferal } = useMutationEditRefferal(
        (res) => {
            refetchRefferalDataList();
            handleCancel();
            notification.success({
                message: res?.message
            });
        }
    );

    const handleView = (id, additionalData) => navigate(`/refferal-code/detail/${id}`, { state: { additionalData } });
    const handleEdit = (res) => {
        selectedIDRef.current = res.id;
        const filterByID = res?.eligiblePlanName?.map((item) => item.id);
        const payload = {
            coupon_code: res?.couponCode,
            per_user_limit: res?.perUserLimit,
            start_date: res?.startDate ? dayjs(res.startDate) : dayjs(),
            end_date: res?.endDate ? dayjs(res.endDate) : dayjs(),
            subscriptionPlanIds: filterByID
        };
        showModal(true, payload);
    };
    const handleDelete = (id) => {
        selectedIDRef.current = id;
        setDeleteModalVisibility(true);
    };
    const columns = RefferalCodeColumns(handleView, handleEdit, handleDelete);
    const handleSearch = (value) => {
        setSearch(value);
    };
    const showModal = (edit = false, values = {}) => {
        setIsEditing(edit);
        setInitialValues(values);
        setIsModalVisible(true);
    };
    const handleFilterChange = (value) => {
        setFilterByStatus(value);
    };
    const handleCreatePlan = () => {
        showModal(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        setInitialValues({});
    };
    const handleFinish = async (values) => {
        const idToUpdatedItem = selectedIDRef.current;
        const payload = {
            ...values,
            per_user_limit: parseInt(values.per_user_limit, 10)
        };
        if (isEditing) {
            updateRefferalDetailMutateQuery({ payload: payload, id: idToUpdatedItem });
        } else {
            const payload = {
                ...values,
                per_user_limit: parseInt(values.per_user_limit, 10),
                start_date: values.start_date ? dayjs(values.start_date).utc().format() : null,
                end_date: values.end_date ? dayjs(values.end_date).utc().format() : null
            };
            CreateRefferalCodeMutateQuery({
                payload
            });
        }
    };
    const eligiblePlansListOptions = eligiblePlansList?.data?.map((item) => ({
        label: item.name,
        value: item.id
    }));
    const handleDeleteModalConfirm = async () => {
        const idToDelete = selectedIDRef.current;
        deleteSubscriptionMutation({
            id: idToDelete
        });
    };
    const handleCreatedDateModalCancel = () => {
        setisDateCreatedModal(false);
    };
    const handleCreatedDateModaOpen = () => {
        setisDateCreatedModal(true);
    };
    const handleValidityModalModalCancel = () => {
        setisValidityModal(false);
    };
    const handleValidityModalOpen = () => {
        setisValidityModal(true);
    };
    const filterByValidityDate = (month, year) => {
        setvalidityData({ month, year });
        setisValidityModal(false);
    };
    const filterByDateCreated = (fromDate, toDate) => {
        if (fromDate && toDate) {
            const fromDateFormatted = dayjs(fromDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            const toDateFormatted = dayjs(toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            setSelectedDateCreated({ start_date: fromDateFormatted, end_date: toDateFormatted });
        } else {
            setSelectedDateCreated({ start_date: fromDate, end_date: toDate });
        }
        setisDateCreatedModal(false);
    };
    return {
        setPage,
        search,
        columns,
        page,
        handleSearch,
        handleFilterChange,
        handleCreatePlan,
        isModalVisible,
        handleCancel,
        handleFinish,
        initialValues,
        isEditing,
        eligiblePlansListOptions,
        refferalDataList,
        filterByStatus,
        refetchRefferalDataList,
        setDeleteModalVisibility,
        deleteModalVisibility,
        handleDeleteModalConfirm,
        isLoading: isLoading_refferalList || isLoading_eligiblePlansList || isLoading_DeleteSubscription || isLoadingCreateRefferal || isLoadingUpdateRefferal,
        isDateCreatedModal,
        handleCreatedDateModalCancel,
        handleCreatedDateModaOpen,
        isValidityModal,
        handleValidityModalModalCancel,
        handleValidityModalOpen,
        filterByValidityDate,
        validityData,
        filterByDateCreated,
        selectedDateCreated
    };
}

