import { Row } from "antd";
import GridView from "Components/GridView/GridView";
import SearchInput from "Components/SearchInput/SearchInput";
import { PAGE_SIZE } from "queries/queries.constant";
import { useEffect } from "react";
import { userTypes } from "util/util.constant";
import { DateCreatedModal, DeleteConfirmationModal, FilterAndCreate, RefferalModal, ValidityModal } from "./Components";
import { statusOptions } from "./constants";
import "./RefferalCode.less";
import useRefferalCodeContainer from "./RefferalCodeContainer";

function RefferalCode() {
    const {
        columns,
        handleSearch,
        search,
        setPage,
        handleFilterChange,
        handleCreatePlan,
        isModalVisible,
        handleCancel,
        handleFinish,
        initialValues,
        isEditing,
        eligiblePlansListOptions,
        refferalDataList,
        filterByStatus,
        refetchRefferalDataList,
        deleteModalVisibility,
        setDeleteModalVisibility,
        handleDeleteModalConfirm,
        isLoading,
        isDateCreatedModal,
        handleCreatedDateModalCancel,
        handleCreatedDateModaOpen,
        isValidityModal,
        handleValidityModalModalCancel,
        handleValidityModalOpen,
        filterByValidityDate,
        validityData,
        selectedDateCreated,
        filterByDateCreated
    } = useRefferalCodeContainer();

    useEffect(() => {
        setPage(1);
        const userQueryPayload = {
            page: 1,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE,
            search: search,
            status: filterByStatus,
            ...(validityData.month && { month: validityData.month }),
            ...(validityData.year && { year: validityData.year }),
            ...(selectedDateCreated.start_date && { start_date: selectedDateCreated.start_date }),
            ...(selectedDateCreated.end_date && { end_date: selectedDateCreated.end_date })
        };
        refetchRefferalDataList(userQueryPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, filterByStatus, validityData, selectedDateCreated]);
    return (
        <div className="refferal-code-container">
            <Row justify="space-between" align="middle">
                <SearchInput onSearch={({ target }) => handleSearch(target.value)} />
                <FilterAndCreate
                    OnClickCreateDate={handleCreatedDateModaOpen}
                    statusOptions={statusOptions}
                    onFilterChange={handleFilterChange}
                    onCreatePlan={handleCreatePlan}
                    onClickValidity={handleValidityModalOpen}
                />
            </Row>
            <div className="refferal-code-container__table">
                <GridView
                    title={() => "Referral Code Management"}
                    data={refferalDataList?.data}
                    loading={isLoading}
                    columns={columns}
                    pagination={{
                        currentPage: refferalDataList?.currentPage,
                        pageSize: PAGE_SIZE,
                        total: refferalDataList?.totalCount,
                        onChange: setPage
                    }}
                />
            </div>
            <DeleteConfirmationModal
                isLoading={isLoading}
                isVisible={deleteModalVisibility}
                onConfirm={handleDeleteModalConfirm}
                onCancel={() => setDeleteModalVisibility(false)}
            />
            <RefferalModal
                isVisible={isModalVisible}
                onCancel={handleCancel}
                onFinish={handleFinish}
                initialValues={initialValues}
                isEditing={isEditing}
                eligiblePlansOptions={eligiblePlansListOptions}
                isLoading={isLoading}
            />
            <DateCreatedModal
                isVisible={isDateCreatedModal}
                onCancel={handleCreatedDateModalCancel}
                onConfirm={filterByDateCreated}
            />
            <ValidityModal
                isVisible={isValidityModal}
                onCancel={handleValidityModalModalCancel}
                onConfirm={filterByValidityDate}
            />
        </div>
    );
}

export default RefferalCode;

