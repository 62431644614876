/* eslint-disable react-hooks/exhaustive-deps */
import { Col, message, Modal, Row } from "antd";
import DashboardCard from "Components/Card/DashboardCard";
import GridView from "Components/GridView/GridView";
import { useQueryDashboardAnalytics, useQueryUpdateUser, useQueryUsers } from "queries/dashboard.queries";
import { PAGE_SIZE } from "queries/queries.constant";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userTypes } from "util/util.constant";
import { EditDetailModal, ResetPasswordModal } from "./Components";
import { cardList, userManagementColumns } from "./constants";
import "./UserManagement.less";

const UserManagement = () => {
    const [isEditDetailModal, setisEditDetailModal] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [page, setPage] = useState(1);
    const [modalVisibility, setModalVisibility] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const search = useSelector((state) => state.search.search);
    let userQueryPayload = {
        page,
        isPagination: true,
        type: userTypes.user,
        take: PAGE_SIZE,
        search: search
    };
    const { data: users, isLoading: userLoading, refetch: refetchUser } = useQueryUsers(userQueryPayload);
    const { data: analytics, isLoading: analyticsLoading } = useQueryDashboardAnalytics();
    //Edit/Update User Password

    const { mutate: UpdateUserPassword, isLoading: isLoadingUpdateUserPassword } = useQueryUpdateUser((res) => {
        userQueryPayload = {};
        refetchUser({
            page,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE
        });
        message.success('Changes Saved!');
        setIsResetPassword(false);
    });
    //Edit/Update Users Details
    const { mutate: UpdateGuestDetail, isLoading: isLoadingEditGuestDetail } = useQueryUpdateUser((res) => {
        userQueryPayload = {};
        refetchUser({
            page,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE
        });
        message.success("Changes Saved!");
        setisEditDetailModal(false);
    });
    const { mutate: updateUserMutateQuery, isLoading: updateUserLoading } = useQueryUpdateUser((res) => {
        userQueryPayload = {};
        message.success(
            `${selectedUser?.user?.firstName} ${selectedUser?.user?.lastName} ${
                parseInt(selectedUser?.user?.status) ? "blocked" : "unblocked"
            }!`
        );
        setSelectedUser(null);
        setModalVisibility(false);
        refetchUser({
            page,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE
        });
    });

    useEffect(() => {
        setPage(1);
        userQueryPayload = {
            page: 1,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE,
            search: search
        };
        refetchUser(userQueryPayload);
    }, [search]);

    const handleView = () => {
        console.log("viewing action");
    };

    const handleAction = () => {
        const payload = {
            status: parseInt(selectedUser?.user?.status) ? "0" : "1"
        };

        updateUserMutateQuery({
            payload,
            userId: selectedUser?.id
        });
    };
    const onClickEdit = (values) => {
        setSelectedUser(values);
        const payload = {
            email: values?.user?.email,
            phone: values?.user?.details?.phone
        };

        setisEditDetailModal(true);
        setInitialValues(payload);
    };
    const onClickPassword = () => {
        setIsResetPassword(true);
    };
    const handleSubmitEditDetail = async (values) => {
        const payload = {
            email: values.email,
            phone: values.phone
        };
        UpdateGuestDetail({
            payload,
            userId: selectedUser?.id
        });
    };
    const handleSubmitResetPassword = (value) => {
        const payload = {
            password: value?.password
        };
        UpdateUserPassword({
            payload,
            userId: selectedUser?.id
        });
    };
    const columns = userManagementColumns(
        handleView,
        setModalVisibility,
        setSelectedUser,
        onClickEdit,
        onClickPassword
    );
    return (
        <div className="user-management-container">
            <div className="user-management-container__card-container">
                <Row gutter={[12, 12]}>
                    {cardList.map((cardInfo, index) => (
                        <Col lg={6} md={12} sm={12} xs={24} key={index}>
                            <DashboardCard
                                cardInfo={cardInfo}
                                analyticsLoading={analyticsLoading}
                                analytics={analytics}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            <div className="user-management-container__table">
                <GridView
                    title={() => "Users"}
                    data={users?.data}
                    loading={userLoading}
                    columns={columns}
                    pagination={{
                        currentPage: users?.currentPage,
                        pageSize: PAGE_SIZE,
                        total: users?.totalCount,
                        onChange: setPage
                    }}
                />
            </div>
            <Modal
                className="user-management-container__modal"
                open={modalVisibility}
                centered
                onOk={handleAction}
                onCancel={() => setModalVisibility(false)}
                okButtonProps={{ loading: updateUserLoading }}
            >
                <div>
                    Are you sure you want to {parseInt(selectedUser?.user?.status) ? "block" : "unblock"}{" "}
                    {selectedUser?.user?.firstName} {selectedUser?.user?.lastName}?
                </div>
            </Modal>
            <EditDetailModal
                onFinish={handleSubmitEditDetail}
                initialValues={initialValues}
                isVisible={isEditDetailModal}
                isLoading={isLoadingEditGuestDetail}
                onCancel={() => setisEditDetailModal(false)}
            />
            <ResetPasswordModal
                isLoading={isLoadingUpdateUserPassword}
                isVisible={isResetPassword}
                onCancel={() => setIsResetPassword(false)}
                onFinish={handleSubmitResetPassword}
            />
        </div>
    );
};

export default UserManagement;

