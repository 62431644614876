import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Input, message, Modal, Row, Select, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import deleteIcon from "../../assets/all-svg-files/delete.svg";
import editIcon from "../../assets/all-svg-files/edit.svg";
import filter_alt from "../../assets/all-svg-files/filter_alt.svg";
import trashCan from "../../assets/all-svg-files/trash-can.svg";
import viewIcon from "../../assets/all-svg-files/view.svg";

import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { Link } from "react-router-dom";
import { monthOptions, yearOptions } from "./constants";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
const { Option } = Select;
const CardViewDetail = ({
    title,
    couponCode,
    perUserLimit,
    status,
    startDate,
    endDate,
    createdDate,
    eligiblePlanName
}) => {
    const { Paragraph } = Typography;
    const planNames = eligiblePlanName
        .slice(0, 5)
        .map((plan) => plan.name)
        .join(", ");

    const finalOutput = eligiblePlanName.length > 5 ? `${planNames}...` : planNames;

    const leftColumn = [
        {
            id: 1,
            label: "Coupon Code",
            value: couponCode || "N/A"
        },
        {
            id: 2,
            label: "Per User Limit",
            value: perUserLimit || "N/A"
        },
        {
            id: 3,
            label: "Status",
            value: status || "N/A"
        }
    ];
    const rightColumn = [
        {
            id: 1,
            label: "Start Date",
            value: dayjs(startDate).format("MM-DD-YYYY")
        },
        {
            id: 2,
            label: "End Date",
            value: dayjs(endDate).format("MM-DD-YYYY")
        },
        {
            id: 3,
            label: "Eligible Plans",
            value: planNames ? finalOutput : "N/A"
        },
        {
            id: 4,
            label: "Creation Date",
            value: dayjs(createdDate).format("MM-DD-YYYY")
        }
    ];

    const style = {
        container: {
            marginBottom: 26
        },
        headerSec: {
            flexDirection: "row",
            display: "flex",
            marginBottom: 10
        },
        gridStyle: {
            minWidth: "50%",
            maxWidth: "100%"
        },
        label: {
            color: "#535353",
            fontWeight: "600"
        },
        value: {
            color: "#8A8989",
            fontWeight: "500"
        }
    };
    return (
        <div style={style.container}>
            <div style={style.headerSec}>
                <Link to={-1} style={{ display: "flex", marginRight: 10 }}>
                    <LeftOutlined style={{ fontSize: "1.3em" }} />
                </Link>
                <h1 style={{ margin: 0 }}>{title}</h1>
            </div>
            <Card style={style.gridStyle}>
                <Row gutter={16}>
                    <Col xs={24} xl={12}>
                        {leftColumn.map((item) => {
                            return (
                                <Row className="detail-row" justify={"space-between"} key={item.id}>
                                    <Paragraph style={style.label}>{item.label}</Paragraph>
                                    <Paragraph style={style.value}>{item.value}</Paragraph>
                                </Row>
                            );
                        })}
                    </Col>
                    <Col xs={24} xl={12}>
                        {rightColumn.map((item) => {
                            return (
                                <Row className="detail-row" justify={"space-between"} key={item.id}>
                                    <Paragraph style={style.label}>{item.label}</Paragraph>
                                    <Paragraph style={style.value}>{item.value}</Paragraph>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

const RefferalModal = ({
    isVisible,
    onCancel,
    onFinish,
    initialValues = {},
    isEditing,
    eligiblePlansOptions = [],
    isLoading
}) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };

    const handleSelectChange = (field) => (values) => {
        form.setFieldsValue({ [field]: values });
    };

    useEffect(() => {
        const resetAndSetValue = () => {
            form.resetFields();
            form.setFieldsValue(initialValues);
        };
        resetAndSetValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues, isEditing]);

    // Custom validator for Start Date and End Date
    const validateDates = () => {
        const startDate = form.getFieldValue("start_date");
        const endDate = form.getFieldValue("end_date");

        if (!startDate || !endDate) {
            return;
        }

        if (startDate.isAfter(endDate)) {
            message.error("The 'Start Date' cannot be after the 'End Date'.");
            form.setFields([
                { name: "start_date", errors: ["Start Date must be before or the same as End Date"] },
                { name: "end_date", errors: ["End Date must be after or the same as Start Date"] }
            ]);
            return false;
        }
        return true;
    };

    const handleFormSubmit = (values) => {
        if (validateDates()) {
            onFinish(values);
        }
    };

    // Function to disable past dates using Day.js
    const disabledDate = (current) => {
        // Allow only today or future dates
        return current && current < dayjs().startOf("day"); // Use Day.js for date comparison
    };

    return (
        <Modal footer={null} open={isVisible} centered onCancel={handleCancel} width={700}>
            <div className="refferal-code-container__modal">
                <h2 className="__editSubscriptionTitle">{isEditing ? "Edit Referral Code" : "Create Referral Code"}</h2>
                <Form
                    form={form}
                    name="RefferalCodeForm"
                    onFinish={handleFormSubmit}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="coupon_code"
                                rules={[{ required: true, message: "Enter Coupon Code" }]}
                                label="Coupon Code"
                            >
                                <Input placeholder="Enter Coupon Code" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                rules={[{ required: true, message: "Enter User Limit" }]}
                                name="per_user_limit"
                                label="Per User Limit"
                            >
                                <Input placeholder="User Limit" type="number" min={1} maxLength={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="start_date"
                                rules={[
                                    { required: true, message: "Start Date is required" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const endDate = getFieldValue("end_date");
                                            if (endDate && value && value.isAfter(endDate)) {
                                                return Promise.reject(new Error("Start Date cannot be after End Date"));
                                            }
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                                label="Start Date"
                            >
                                <DatePicker
                                    className="datePickerStyling"
                                    disabledDate={disabledDate} // Disable past dates
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="end_date"
                                rules={[
                                    { required: true, message: "End Date is required" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const startDate = getFieldValue("start_date");
                                            if (startDate && value && value.isBefore(startDate)) {
                                                return Promise.reject(
                                                    new Error("End Date cannot be before Start Date")
                                                );
                                            }
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                                label="End Date"
                            >
                                <DatePicker
                                    className="datePickerStyling"
                                    disabledDate={disabledDate} // Disable past dates
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                rules={[{ required: true, message: "Please Select Eligible Plans" }]}
                                name="subscriptionPlanIds"
                                label="Eligible Plans"
                            >
                                <Select
                                    mode="multiple"
                                    defaultValue={initialValues.subscriptionPlanIds}
                                    className="RefferalDuration"
                                    placeholder="Select Eligible Plans"
                                    onChange={handleSelectChange("subscription_by")}
                                    options={eligiblePlansOptions}
                                    showArrow
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {isEditing ? "Update" : "Create"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
const ActionIcons = ({ onView, onEdit, onDelete }) => {
    return (
        <div className="user-management-container__action-container">
            <img src={viewIcon} alt="view icon" onClick={onView} />
            <img src={editIcon} alt="edit icon" onClick={onEdit} />
            <img src={deleteIcon} alt="delete icon" onClick={onDelete} />
        </div>
    );
};

const FilterAndCreate = ({
    filterLabel = "Filter By",
    statusOptions,
    onFilterChange,
    onCreatePlan,
    OnClickCreateDate,
    onClickValidity
}) => {
    const { Option } = Select;

    return (
        <Row align="middle" gutter={16} className="FilterAndCreateContainer">
            <Col>
                <span>{filterLabel}</span>
            </Col>
            <Col>
                <Select placeholder="Status" onChange={onFilterChange} style={{ width: 150 }}>
                    {statusOptions.map((status, index) => (
                        <Option key={index} value={status.value}>
                            {status.label}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col>
                <Button type="primary" className="filterDateBtn" onClick={OnClickCreateDate}>
                    <span>Date Created</span>
                    <span>
                        <img src={filter_alt} alt="view icon" />
                    </span>
                </Button>
            </Col>
            {/* <Col>
                <Button type="primary" className="filterDateBtn" onClick={onClickValidity}>
                    <span>Validity</span>
                    <span>
                        <img src={filter_alt} alt="view icon" />
                    </span>
                </Button>
            </Col> */}

            <Col>
                <Button type="primary" style={{ backgroundColor: "#a89882" }} onClick={onCreatePlan}>
                    Create Referral Code
                </Button>
            </Col>
        </Row>
    );
};
const DeleteConfirmationModal = ({ isVisible, onConfirm, onCancel, isLoading }) => {
    return (
        <Modal
            className="refferal-code-container__modal"
            open={isVisible}
            centered
            onOk={onConfirm}
            onCancel={onCancel}
            cancelText="No"
            okText="Yes"
            confirmLoading={isLoading}
            cancelButtonProps={{disabled: isLoading}}
        >
            <div className="deleteModal">
                <img src={trashCan} alt="Trash Can" />
                <p>Are you sure you want to delete this</p>
                <p>Referral code?</p>
            </div>
        </Modal>
    );
};
const DateCreatedModal = ({ isVisible, onConfirm, onCancel }) => {
    const [dates, setDates] = useState([null, null]);

    const handleApply = () => {
        if (dates[0] && dates[1]) {
            if (dates[0].isAfter(dates[1])) {
                message.error("The 'From' date cannot be after the 'To' date.");
                return;
            }

            const fromDate = dates[0].format("YYYY-MM-DD");
            const toDate = dates[1].format("YYYY-MM-DD");

            onConfirm(fromDate, toDate);
        } else {
            message.error("Please select both 'From' and 'To' dates.");
        }
    };

    const handleReset = () => {
        setDates([null, null]);
        onConfirm(null, null);
    };

    const handleOnClick = () => {
        handleApply();
    };
    const isApplyDisabled = !dates[0] || !dates[1];

    return (
        <Modal footer={null} open={isVisible} centered onCancel={onCancel} width={700}>
            <div className="refferal-code-container__dateCreatedModalContainer">
                <h2 className="title">Date</h2>
                <Row gutter={16}>
                    <Col span={12}>
                        <p className="label">From</p>
                        <DatePicker
                            className="datePickerStyling"
                            placeholder="From"
                            value={dates[0]}
                            onChange={(date) => setDates([date, dates[1]])}
                        />
                    </Col>
                    <Col span={12}>
                        <p className="label">To</p>
                        <DatePicker
                            className="datePickerStyling"
                            placeholder="To"
                            value={dates[1]}
                            onChange={(date) => setDates([dates[0], date])}
                        />
                    </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
                    <Button disabled={isApplyDisabled} type="primary" className="btnStyle" onClick={handleOnClick}>
                        Apply
                    </Button>
                    <Button type="primary" htmlType="submit" className="btnStyleReset" onClick={handleReset}>
                        Clear
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const ValidityModal = ({ isVisible, onConfirm, onCancel }) => {
    const [fromMonth, setFromMonth] = useState(null);
    const [toYear, setToYear] = useState(null);
    const isApplyDisabled = !fromMonth || !toYear;

    const handleApply = () => {
        if (fromMonth && toYear) {
            // Construct the start and end dates
            const month = `${fromMonth}`; // Default year 2024, start of the month
            const year = `${toYear}`; // End of the selected year

            // Call onConfirm with selected dates
            onConfirm(month, year);
        }
    };

    const handleOnClick = () => {
        handleApply();
    };
    const resetFields = () => {
        setFromMonth(null);
        setToYear(null);
        onConfirm(null, null);
    };

    return (
        <Modal footer={null} open={isVisible} centered onCancel={onCancel} width={700}>
            <div className="refferal-code-container__dateCreatedModalContainer">
                <h2 className="title">Date</h2>
                <Row gutter={16}>
                    <Col span={12}>
                        <p className="label">From Month</p>
                        <Select
                            className="selectStyling"
                            placeholder="Select From Month"
                            value={fromMonth}
                            onChange={(value) => setFromMonth(value)}
                        >
                            {monthOptions.map(({ name, value }) => (
                                <Option key={value} value={value}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={12}>
                        <p className="label">To Year</p>
                        <Select
                            className="selectStyling"
                            placeholder="Select To Year"
                            value={toYear}
                            onChange={(value) => setToYear(value)}
                        >
                            {yearOptions.map((year) => (
                                <Option key={year} value={year}>
                                    {year}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="btnStyle"
                    onClick={handleOnClick}
                    disabled={isApplyDisabled}
                >
                    Apply
                </Button>
                <Button type="primary" htmlType="submit" className="btnStyleReset" onClick={resetFields}>
                    Clear
                </Button>
            </div>
        </Modal>
    );
};

export {
    ActionIcons,
    CardViewDetail,
    DateCreatedModal,
    DeleteConfirmationModal,
    FilterAndCreate,
    RefferalModal,
    ValidityModal
};

