import { Button } from "antd";
import totalUsersIcon from "../../assets/all-svg-files/total-users-icon.svg";
import archiveIcon from "../../assets/all-svg-files/archive-icon.svg";
import tagIcon from "../../assets/all-svg-files/tag-icon.svg";
import viewIcon from "../../assets/all-svg-files/view.svg";
import blockIcon from "../../assets/all-svg-files/block.svg";
import unlinkIcon from "../../assets/all-svg-files/unlink.svg";
import dayjs from "dayjs";
import { EditTwoTone, LockTwoTone } from "@ant-design/icons";

export const userManagementColumns = (handleView, setModalVisibility, setSelectedUser, onClickEdit,onClickPassword) => {
    return [
        {
            title: "User ID",
            key: "userId",
            dataIndex: "id"
        },

        {
            title: "Name",
            key: "name",
            render: (res) => <span>{`${res?.user?.firstName} ${res?.user?.lastName}`}</span>
        },

        {
            title: "Email",
            key: "email",
            render: (res) => <span>{res?.user?.email}</span>
        },

        {
            title: "Date of Joining",
            key: "DoJ",
            render: (res) => <span>{dayjs(res?.user?.createdAt).format("MM-DD-YYYY")}</span>
        },

        {
            title: "Status",
            key: "status",
            render: (res) => (
                <Button
                    className={`user-management-container__status user-management-container__status-${
                        parseInt(res?.user?.status) > 0 ? "active" : "blocked"
                    }`}
                >
                    {parseInt(res?.user?.status) > 0 ? "Active" : "Blocked"}
                </Button>
            )
        },

        {
            title: "Actions",
            key: "actions",
            render: (res) => (
                <div className="user-management-container__action-container">
                    <img src={viewIcon} alt="view icon" onClick={handleView} />
                    <EditTwoTone  style={{ fontSize: "2.2rem", marginRight: "2rem" }}  onClick={()=>onClickEdit(res)}/>
                    <LockTwoTone  style={{ fontSize: "2.2rem", marginRight: "2rem" }}  onClick={onClickPassword}/>
                    {parseInt(res?.user?.status) ? (
                        <img
                            src={blockIcon}
                            alt="block icon"
                            onClick={() => {
                                setModalVisibility(true);
                                setSelectedUser(res);
                            }}
                        />
                    ) : (
                        <img
                            src={unlinkIcon}
                            alt="unlink icon"
                            onClick={() => {
                                setModalVisibility(true);
                                setSelectedUser(res);
                            }}
                        />
                    )}
                </div>
            )
        }
    ];
};

export const dataSource = [
    {
        id: 2540,
        name: "Neal Matthews",
        email: "nathan.roberts@example.com",
        doj: "March 23, 2013",
        status: true
    },
    {
        id: 2541,
        name: "Jamal Burnett",
        email: "alma.lawson@example.com",
        doj: "April 28, 2016",
        status: false
    },
    {
        id: 2542,
        name: "Juan Mitchel",
        email: "bill.sanders@example.com",
        doj: "February 9, 2015",
        status: true
    },
    {
        id: 2543,
        name: "Barry Dick",
        email: "jackson.graham@example.com",
        doj: "August 2, 2013",
        status: false
    },
    {
        id: 2544,
        name: "Ronald Taylor",
        email: "kenzi.lawson@example.com",
        doj: "December 19, 2013",
        status: false
    },
    {
        id: 2545,
        name: "Jacob Hunter",
        email: "willie.jennings@example.com",
        doj: "May 12, 2019",
        status: true
    }
];

export const cardList = [
    {
        title: "Total Users",
        key: "allUsers",
        count: "1235",
        icon: totalUsersIcon
    },

    {
        title: "Total Events",
        count: "351",
        key: "allEvents",
        icon: archiveIcon
    },

    {
        title: "Upcoming Events",
        count: "05",
        key: "allUpcomingEvents",
        icon: tagIcon
    },

    {
        title: "Forever After Events",
        count: "16",
        key: "allExpireEvents",
        icon: tagIcon
    }
];
